import { compose } from '@xo-union/react-css-modules';
import React, { type FC } from 'react';
import type { useInlineRfqForm } from '../../hooks/useInlineRfqForm/useInlineRfqForm';
import Styles from './FormBody.scss';
import DateFlexible from './components/DateFlexible/DateFlexible';
import DatePickerField from './components/DatePickerField/DatePickerField';
import GuestCountSelect from './components/GuestCountSelect/GuestCountSelect';
import InputField from './components/InputField/InputField';
import PhoneField from './components/PhoneField/PhoneField';
import TextareaField from './components/TextareaField/TextareaField';

export interface FormBodyProps {
	context: ReturnType<typeof useInlineRfqForm>;
}

export const FormBody: FC<FormBodyProps> = ({ context }) => {
	const containerClassNames = {
		container: Styles.container,
		'sub-text': Styles.subtext,
	};

	const inputClassNames = {
		...containerClassNames,
		input: 'fs-mask',
	};

	const datePickerClassNames = {
		container: Styles.datepickerContainer,
	};

	const inputClasses = compose(inputClassNames);
	const containerClasses = compose(containerClassNames);
	const datePickerClasses = compose(datePickerClassNames);

	return (
		<form>
			<div className={Styles.namesContainer}>
				<InputField
					context={context}
					classes={inputClasses}
					name="firstName"
					autoComplete="given-name"
					isRequired
				/>
				<InputField
					context={context}
					classes={inputClasses}
					name="lastName"
					autoComplete="family-name"
					isRequired
				/>
			</div>
			<InputField
				context={context}
				classes={inputClasses}
				name="emailAddress"
				autoComplete="email"
				isRequired
			/>
			<DatePickerField
				context={context}
				datePickerStyle={datePickerClasses}
				isRequired
			/>
			<DateFlexible context={context} />
			<GuestCountSelect
				context={context}
				selectStyle={containerClasses}
				isRequired
			/>
			<PhoneField context={context} classes={inputClasses} />
			<TextareaField context={context} isRequired />
		</form>
	);
};

export default FormBody;
