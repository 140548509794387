import type { ClassesMapper } from '@xo-union/react-css-modules';
import { Field } from '@xo-union/tk-component-fields';
import React, { type ChangeEvent, useMemo, useState, type FC } from 'react';
import { connect } from 'react-redux';
import type { useInlineRfqForm } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { handleFieldChange } from '../../../../utils';
import { getFieldLabel } from '../helpers';

export interface OwnProps {
	autoComplete: Rfq.InlineAutoCompleteAttributes;
	classes: ClassesMapper<string>;
	name: Rfq.InlineFieldKeys;
	context: ReturnType<typeof useInlineRfqForm>;
	isRequired?: boolean;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type InputFieldProps = OwnProps & StateProps;

export const InputField: FC<InputFieldProps> = (props) => {
	const {
		formFields,
		autoComplete,
		classes,
		name,
		context,
		isRequired = false,
	} = props;
	const { values: contextFields, setFieldValue, shouldShowErrors } = context;
	const [value, setValue] = useState<string>(formFields[name].value);
	const data = contextFields[name];

	// biome-ignore lint/correctness/useExhaustiveDependencies: input cannot be changed if we add "value" as dependency
	useMemo(() => {
		if (data.value && data.value !== value) {
			setValue(data.value);
		}
	}, [data.value]);

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);
	};

	return (
		<Field
			autoComplete={autoComplete}
			classes={classes}
			label={getFieldLabel(data.label, isRequired)}
			name={autoComplete}
			value={value}
			onBlur={() => {
				handleFieldChange({
					cb: setFieldValue,
					data,
					name,
					value,
				});
			}}
			onChange={handleOnChange}
			state={shouldShowErrors ? data.state : 'neutral'}
			subText={shouldShowErrors ? data.error : ''}
		/>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	formFields: state.rfq.inline.fields,
});

export default connect(mapStateToProps)(InputField);
