import NavigableModal from '@components/NavigableModal/NavigableModal';
import { reportServerSideExperiment } from '@redux/experiments';
import { isRfqModalReducedHeightSelector } from '@redux/experiments/selectors/rfq-modal-reduced-height';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { experiments } from '@settings';
import { compose } from '@xo-union/react-css-modules';
import React, { useEffect } from 'react';
import type { FCWithChildren } from 'types/react-extended';
import type { UseInlineRfqFormReturn } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { checkIsTablet } from '../../../../utils';
import Styles from './InlineRfqModalWrapper.scss';

const tabletModalClasses = compose({
	'button-container': Styles.tabletButtonContainer,
	'close-button': Styles.tabletCloseButton,
	large: Styles.tabletModal,
});

const mobileModalClasses = compose({
	container: Styles.container,
	content: Styles.content,
});

const mobileModalClassesControl = compose({
	container: Styles.container,
	content: Styles.content,
	'modal-overlay': Styles.modalOverlay,
});

export interface ModalWrapperProps {
	context: UseInlineRfqFormReturn;
	viewport: Redux.Viewport;
	onClose: () => void;
	isModalOnModal?: boolean;
}

export const InlineRfqModalWrapper: FCWithChildren<ModalWrapperProps> = ({
	context,
	children,
	viewport,
	onClose,
	isModalOnModal = false,
}) => {
	const isRfqModalReducedHeightVariant = useAppSelector(
		isRfqModalReducedHeightSelector,
	);
	const isTabletOrMedium = checkIsTablet(viewport);
	const { isMobile } = viewport;
	const isDesktop = !isTabletOrMedium && !isMobile;

	const dispatch = useAppDispatch();

	useEffect(() => {
		// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
		console.log(
			'RFQ modal reduced height assignment: ',
			isRfqModalReducedHeightVariant,
		);
		dispatch(reportServerSideExperiment(experiments.rfqModalReducedHeight));
	}, [dispatch, isRfqModalReducedHeightVariant]);

	let classes: ReturnType<typeof compose>;
	if (isMobile) {
		classes = mobileModalClasses;
	} else if (isTabletOrMedium) {
		classes = tabletModalClasses;
	} else
		classes = compose({
			small: isRfqModalReducedHeightVariant ? Styles.containerDesktop : '',
		});

	return (
		<NavigableModal
			hash="rfq-modal"
			isModalOpen={context.isModalOpen}
			closeCallback={onClose}
			classes={classes}
			size={isDesktop ? 'sm' : 'lg'}
			isModalOnModal={isModalOnModal}
		>
			{children}
		</NavigableModal>
	);
};
