import { useEscape } from '@hooks/useEscape';
import React, { type FC } from 'react';
import { connect } from 'react-redux';
import InlineRFQ from '../../InlineRfq';
import type { useInlineRfqForm } from '../../hooks/useInlineRfqForm/useInlineRfqForm';
import { useTrackInlineForm } from '../../hooks/useTrackInlineForm/use-track-inline-form';
import { InlineRfqModalWrapper } from './components/InlineRfqModalWrapper/InlineRfqModalWrapper';

export interface OwnProps {
	context: ReturnType<typeof useInlineRfqForm>;
	handleCloseInlineRfq?: () => void;
	handleSubmittedInlineRfq?: () => void;
	isModalOnModal?: boolean;
}
type StateProps = ReturnType<typeof mapStateToProps>;
type InlineRfqModalProps = StateProps & OwnProps;

export const InlineRfqModal: FC<InlineRfqModalProps> = (props) => {
	const {
		context,
		viewport,
		handleCloseInlineRfq,
		handleSubmittedInlineRfq,
		isModalOnModal = false,
	} = props;
	const { isModalOpen, setIsModalOpen } = context;
	const trackCloseInlineForm = useTrackInlineForm('close', context.initiator);

	const closeModal = () => {
		trackCloseInlineForm();
		setIsModalOpen(false);
		handleCloseInlineRfq?.();
	};
	useEscape(closeModal);

	if (isModalOpen) {
		return (
			<InlineRfqModalWrapper
				context={context}
				viewport={viewport}
				onClose={closeModal}
				isModalOnModal={isModalOnModal}
			>
				<InlineRFQ
					context={context}
					handleSubmittedInlineRfq={handleSubmittedInlineRfq}
				/>
			</InlineRfqModalWrapper>
		);
	}

	return null;
};

const mapStateToProps = (state: Redux.State) => ({
	viewport: state.viewport,
});

export default connect(mapStateToProps)(InlineRfqModal);
