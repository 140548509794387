import { selectAssignment } from '@redux/experiments/selectors/index';
import type { DirectoryInlineRfqAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

const directoryInlineRfqSelector = (state: State) => {
	return selectAssignment(state, [
		'directoryInlineRfq',
	]) as DirectoryInlineRfqAssignment;
};

export { directoryInlineRfqSelector };
