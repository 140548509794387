import { selectAssignment } from '@redux/experiments/selectors/index';
import type { LeadFormSequencing_v1_1_Assignment } from '@typings/experiments';
import type { State } from 'types/redux';

const leadFormSequencingSelector = (state: State) => {
	return selectAssignment(state, [
		'leadFormSequencing_v1_1',
	]) as LeadFormSequencing_v1_1_Assignment;
};

export { leadFormSequencingSelector };
