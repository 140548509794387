import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { useCallback, useMemo } from 'react';
import type { Decorated } from 'types/vendor';
import AnalyticsConstants from '../../../constants/analytics';
import VendorAnalytics from '../../decorators/vendorAnalytics';

type Event = 'Storefront Interaction' | 'Vendor Portfolio Interaction';
type StorefrontSection =
	| 'Availability'
	| 'Packages'
	| 'content highlights'
	| 'Real Weddings'
	| 'Venue Pricing FAQs'
	| 'About Vendor'
	| 'RFQ';
type Selection =
	| 'Click left'
	| 'Click right'
	| 'Click date'
	| 'Click arrow'
	| 'See more'
	| 'See less'
	| 'Read more'
	| 'Hide'
	| 'See details'
	| 'View availability'
	| 'View packages'
	| 'View details'
	| 'Click real wedding'
	| 'Venue Pricing Tooltip visible'
	| 'Peak Season Expand'
	| 'Peak Season Collapse'
	| 'Off peak Season Expand'
	| 'Off peak Season Collapse'
	| 'Tooltip interaction';

const getStorefrontInteractionProperties = (
	storefrontSection: StorefrontSection,
	selection: Selection,
) => ({
	storefrontSection,
	selection,
});

const getVendorPortfolioInteractionProperties = (
	storefrontSection: StorefrontSection,
	selection: Selection,
	vendor: Decorated,
) => ({
	action: selection,
	sourceContent: storefrontSection,
	sourcePage:
		vendor.purchaseStatus === 'PAID'
			? AnalyticsConstants.PAID_STOREFRONT
			: AnalyticsConstants.UNPAID_STOREFRONT,
});

export const useTrackStorefrontInteraction = (
	storefrontSection: StorefrontSection,
	event: Event = 'Storefront Interaction',
	properties?: object,
) => {
	const { track } = useAnalyticsContext();
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const vendorAnalytics = useMemo(() => new VendorAnalytics(vendor), [vendor]);

	return useCallback(
		(selection: Selection, extraProperties?: object) => {
			track({
				event,
				properties: {
					...vendorAnalytics.baseEventProps(),
					...(event === 'Vendor Portfolio Interaction'
						? getVendorPortfolioInteractionProperties(
								storefrontSection,
								selection,
								vendor,
							)
						: {}),
					...(event === 'Storefront Interaction'
						? getStorefrontInteractionProperties(storefrontSection, selection)
						: {}),
					...(properties ?? {}),
					...(extraProperties ?? {}),
				},
			});
		},
		[storefrontSection, event, track, properties, vendorAnalytics, vendor],
	);
};
