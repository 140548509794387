import { selectAssignment } from '@redux/experiments/selectors/index';
import type { VendorRecommendationDistanceAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

const vendorRecommendationDistanceSelector = (state: State) => {
	return selectAssignment(state, [
		'vendorRecommendationDistance',
	]) as VendorRecommendationDistanceAssignment;
};

export { vendorRecommendationDistanceSelector };
