import RfqLegalCopy from '@components/RfqLegalCopy/RfqLegalCopy';
import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { isLiteRFQVendorWidgetSelector } from '@redux/experiments/selectors/lite-rfq-vendor-widget';
import { isRfqModalReducedHeightSelector } from '@redux/experiments/selectors/rfq-modal-reduced-height';
import { useAppSelector } from '@redux/hooks';
import { IconButton } from '@xo-union/tk-component-icons';
import { Caption, Subhead } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { type FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useStorefrontFeatureScannability } from '../../hooks/useStorefrontFeatureScannability';
import { VendorInfo } from '../bio/components/TeamInfo';
import Styles from './InlineRfq.scss';
import FormBody from './components/FormBody/FormBody';
import { HeaderSubText } from './components/HeaderSubText';
import LiteRFQ from './components/LiteRFQ/LiteRFQ';
import QuickResponderBadge from './components/QuickResponderBadge';
import SubmitCTA from './components/SubmitCTA';
import ViewConversation from './components/ViewConversation';
import { WhyUseKnot } from './components/WhyUseKnot';
import type { UseInlineRfqFormReturn } from './hooks/useInlineRfqForm/useInlineRfqForm';
import { useTrackInlineForm } from './hooks/useTrackInlineForm/use-track-inline-form';
import {
	checkIsTablet,
	isPostPrimary,
	loadStoredRfqFormData,
	resolveButtonText,
} from './utils';

type StateProps = ReturnType<typeof mapStateToProps>;

export interface OwnProps {
	context: UseInlineRfqFormReturn;
	handleSubmittedInlineRfq?: () => void;
}

type InlineRfqProps = StateProps & OwnProps;

const InlineRfq: FC<InlineRfqProps> = (props) => {
	const {
		conversations,
		vendorId = '',
		vendorRaw,
		context,
		handleSubmittedInlineRfq,
		inlineFormFields,
		viewport,
		categoryCode,
		membership,
	} = props;

	const trackEditInlineForm = useTrackInlineForm('edit', context.initiator);
	const trackCloseInlineForm = useTrackInlineForm('close', context.initiator);
	const isTabletOrMedium = checkIsTablet(viewport);
	const { isMobile } = viewport;
	const isDesktop = !isMobile && !isTabletOrMedium;
	const shouldRenderQuickResponderBadge = Boolean(
		isDesktop && vendorRaw?.vendorBehavior?.quickResponder,
	);
	const isInDetailedPricing = useDetailedPricing();
	const isInLiteRFQVendorWidgetExperiment = useAppSelector(
		isLiteRFQVendorWidgetSelector,
	);
	const isRfqModalReducedHeightVariant = useAppSelector(
		isRfqModalReducedHeightSelector,
	);

	const shouldShowScannability = useStorefrontFeatureScannability();

	const {
		isSubmitting,
		readyToSubmit,
		areErrorsInForm,
		shouldShowErrors,
		handleCtaClick,
		headerText,
		subheaderText,
		isModalOpen,
		isManuallyEdited,
		setIsModalOpen,
		handleSubmit,
		values,
		pageType,
	} = context;

	useEffect(() => {
		if (conversations[vendorId] && isModalOpen) {
			setIsModalOpen(false);
		} else {
			loadStoredRfqFormData({ context, categoryCode, membership });
		}
	}, []);

	useEffect(() => {
		if (!isSubmitting && readyToSubmit) {
			handleSubmit().then((submitted) => {
				if (submitted) {
					handleSubmittedInlineRfq?.();
				}
			});
		}
	}, [inlineFormFields]);

	const [shouldHideLiteRFQ, setShouldHideLiteRFQ] = useState(false);

	const areErrorsInLiteRFQ = (): boolean => {
		const { firstName, lastName, emailAddress, weddingDate, guestCount } =
			values;

		return (
			firstName.error !== '' ||
			lastName.error !== '' ||
			emailAddress.error !== '' ||
			weddingDate.error !== '' ||
			guestCount.error !== ''
		);
	};

	const shouldRenderLiteRFQ = (): boolean => {
		const { firstName, lastName, emailAddress, weddingDate, guestCount } =
			values;
		// Checking the context should be enough to determine if the form is filled. See utils.ts/loadStoredRfqFormData for more info.
		const errorsInLiteRFQ = areErrorsInLiteRFQ();

		return (
			firstName.value !== '' &&
			lastName.value !== '' &&
			emailAddress.value !== '' &&
			weddingDate.value !== '' &&
			guestCount.value !== '' &&
			!errorsInLiteRFQ &&
			!shouldHideLiteRFQ &&
			!isManuallyEdited
		);
	};

	const renderLiteRFQ = useMemo(() => {
		return shouldRenderLiteRFQ();
	}, [shouldRenderLiteRFQ]);

	const shouldDisplayLiteRFQVendorWidget = useMemo(() => {
		return isMobile
			? pageType === 'storefront'
			: !isModalOpen && isInLiteRFQVendorWidgetExperiment;
	}, [isMobile, pageType, isModalOpen, isInLiteRFQVendorWidgetExperiment]);

	if (
		isDesktop &&
		isPostPrimary(
			conversations,
			vendorId,
			isModalOpen,
			shouldShowErrors,
			areErrorsInForm,
		)
	) {
		return <ViewConversation />;
	}

	const handleCloseModal = () => {
		trackCloseInlineForm();
		setIsModalOpen(false);
	};

	return (
		<>
			<div
				className={classNames({
					[Styles.mobileWrapper]: isMobile && !isTabletOrMedium,
					[Styles.tabletWrapper]: isTabletOrMedium,
					[Styles.inlineSticky]: isDesktop && !isModalOpen,
				})}
				data-detailed-pricing-rfq={isInDetailedPricing}
				data-scannability={shouldShowScannability}
			>
				<div
					className={classNames({
						[Styles.inlineWrapper]: isDesktop && !isModalOpen,
					})}
				>
					<div
						className={classNames({
							[Styles.basePadding]: isDesktop && !isModalOpen,
						})}
					>
						{isTabletOrMedium && (
							<div
								className={classNames({
									[Styles.headerCloseButton]: isTabletOrMedium,
								})}
							>
								<IconButton
									aria-label="close"
									block
									name="close"
									onClick={handleCloseModal}
									size="sm"
								/>
							</div>
						)}
						<div className={Styles.headerTextWrapper}>
							<Subhead bold>{headerText || 'Message vendor'}</Subhead>
							<Caption className={Styles.requiredText}>*=Required</Caption>
						</div>
						<HeaderSubText
							isMobile={isMobile}
							isTabletOrMedium={isTabletOrMedium}
							pricingText={subheaderText}
						/>
						{shouldDisplayLiteRFQVendorWidget && (
							<VendorInfo
								bio={vendorRaw?.bio}
								avatarSize={80}
								isInLiteRFQ={true}
								includeQuickResponderBadge={Boolean(
									vendorRaw?.vendorBehavior?.quickResponder,
								)}
							/>
						)}
						{!renderLiteRFQ && <FormBody context={context} />}
						{renderLiteRFQ && (
							<LiteRFQ
								context={context}
								buttonHandler={() => {
									trackEditInlineForm();
									setShouldHideLiteRFQ(true);
								}}
							/>
						)}
						{!shouldShowScannability && (
							<div className={Styles.avoidStickyCover}>
								<WhyUseKnot />
							</div>
						)}
					</div>
					<div
						className={classNames({
							[Styles.stickyCta]: isMobile,
							[Styles.desktopCta]: isDesktop && !isModalOpen,
							[Styles.stickyDesktopCta]:
								isDesktop && isModalOpen && isRfqModalReducedHeightVariant,
						})}
					>
						{!membership.member && (
							<div
								className={
									isModalOpen
										? Styles.legalCopyContainerModal
										: Styles.legalCopyContainer
								}
							>
								<RfqLegalCopy
									btnText={resolveButtonText({
										catCode: categoryCode,
										isMobile,
										isTablet: isTabletOrMedium,
									})}
								/>
							</div>
						)}
						<SubmitCTA handleSubmit={handleCtaClick} />
						{shouldRenderQuickResponderBadge &&
							!isInLiteRFQVendorWidgetExperiment && (
								<div className={Styles.quickResponderBadgeWrapper}>
									<QuickResponderBadge small={shouldShowScannability} />
								</div>
							)}
						{membership.member && (
							<div className={Styles.termsContainer}>
								<a
									href="https://www.theknotww.com/terms-of-use"
									rel="noopener noreferrer"
									target="_blank"
									className={Styles.termsLink}
								>
									Terms of Use
								</a>
								<a
									href="https://www.theknotww.com/privacy-policy"
									rel="noopener noreferrer"
									target="_blank"
									className={Styles.termsLink}
								>
									Privacy Policy
								</a>
							</div>
						)}
					</div>
				</div>
				{shouldShowScannability && (
					<div className={Styles.whyUseKnotWrapper}>
						<WhyUseKnot />
					</div>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	inlineFormFields: state.rfq.inline.fields,
	categoryCode: state.category.code,
	membership: state.membership,
	conversations: state.messagedVendors.conversations,
	vendorRaw: state.vendor.vendorRaw,
	vendorId: state.vendor.vendorRaw?.id,
	viewport: state.viewport,
});

export default connect(mapStateToProps)(InlineRfq);
