import type { ClassesMapper } from '@xo-union/react-css-modules';
import { Field } from '@xo-union/tk-component-fields';
import React, { type FC, type ChangeEvent } from 'react';
import type { useInlineRfqForm } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { handleFieldChange } from '../../../../utils';

type OnChange = (e: ChangeEvent<HTMLInputElement>) => void;

export interface PhoneFieldProps {
	classes: ClassesMapper<string>;
	context: ReturnType<typeof useInlineRfqForm>;
}

export const PhoneField: FC<PhoneFieldProps> = (props) => {
	const { classes, context } = props;
	const {
		setFieldValue,
		shouldShowErrors,
		values: { phoneNumber },
	} = context;

	const handleOnChange: OnChange = (event) => {
		const { value } = event.target;
		handleFieldChange({
			cb: setFieldValue,
			data: phoneNumber,
			name: 'phoneNumber',
			value,
		});
	};

	return (
		<div>
			<Field
				autoComplete="off"
				classes={classes}
				label={phoneNumber.label}
				name="phoneNumber"
				value={phoneNumber.value}
				onChange={handleOnChange}
				state={shouldShowErrors ? phoneNumber.state : 'neutral'}
				subText={shouldShowErrors ? phoneNumber.error : ''}
			/>
		</div>
	);
};

export default PhoneField;
