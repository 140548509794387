import { selectAssignment } from '@redux/experiments/selectors/index';
import type { RfqModalReducedHeightAssignment } from 'types/experiments';
import type { State } from 'types/redux';

export const rfqModalReducedHeightSelector = (state: State) =>
	selectAssignment(state, [
		'rfqModalReducedHeight',
	]) as RfqModalReducedHeightAssignment;

export const isRfqModalReducedHeightSelector = (state: State) => {
	const assignment = rfqModalReducedHeightSelector(state);
	return assignment?.startsWith('variant1');
};
